const SideService = () => {
    return(
        <div className="flex flex-col items-center">
            <div className="flex vs2:flex-col vs2:text-center vs2:gap-0 gap-3">
                <h1 className="text-[40px] s3m:text-[35px] md2:text-[30px] nsm:text-[25px] msm:text-[21px] font-Poppins ">We're like a Swiss Army </h1>
                <h1 className="text-[40px] s3m:text-[35px] md2:text-[30px] nsm:text-[25px] msm:text-[21px] font-Poppins ">knife of expertise,</h1>
            </div>
            <div className="flex vs2:flex-col vs2:text-center vs2:gap-0 gap-3">
                <h1 className="text-[40px] s3m:text-[35px] md2:text-[30px] nsm:text-[25px] msm:text-[21px] font-Poppins ">ready to <strong className="text-Primary">tackle problems</strong></h1>
                <h1 className="text-[40px] s3m:text-[35px] md2:text-[30px] nsm:text-[25px] msm:text-[21px] font-Poppins ">from every angle!</h1>
            </div>

            <h1 className="text-[24px] md2:text-[21px] nsm:text-[16px] msm:text-[12px] msm:mt-3 text-center mt-5">Here are some of our daily specialties.</h1>
        </div>
    )
}

export default SideService;