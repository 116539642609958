import BottomFooter from "./BottomFooter"
import TopFooter from "./TopFooter"

const Footer = () => {
    return(
        <div className="flex flex-col">
            <TopFooter/>
            <BottomFooter/>
        </div>
    )
}

export default Footer;